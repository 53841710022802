import React from "react";
import "../Home/sportzchains.css";
import "../Home/sportzchains.responsive.css";
import Index from "../../../Index";
import PagesIndex from "../../../../container/PagesIndex";

function Sportzchains() {
  const [data, setData] = PagesIndex.useState([]);

  const getData = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.GET_REWARD_NFT
      );
      setData(res?.data?.data);
    } catch (error) { }
  };

  PagesIndex.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Index.Box className="home-container">
        <Index.Box className="sportzchains-main">
          <Index.Box className="home-title-sec">
            <Index.Typography className="home-title" component="p">
              Stake <span>SPN</span>  Tokens, Unlock <span>NFT</span>  Rewards : <br />
              Your Exclusive Cards Await!
            </Index.Typography>
            <Index.Typography className="sub-title" component="p">
              Stake beyond the SPN token threshold for exclusive NFTs. These cards, a token of gratitude, hold future utilities, including an upcoming buyback feature.
              Elevate your staking journey with rewards that go beyond appreciation – they open doors to unique benefits and future opportunities.
            </Index.Typography>
          </Index.Box>
          <Index.Box className="sportzchain-card-main">
            <Index.Box className="home-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  rowGap={{ xs: 3 }}
                >
                  {data &&
                    data.map((row, index) => (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        key={index}
                      >
                        <Index.Box className="sportzchain-card">
                          <Index.Box className="sportzchain-img-sec">
                            <img
                              src={`${PagesIndex.IMAGE_URL_ENDPOINT}${row?.image}`}
                              alt="sportzCardImg"
                              className="sportzchain-card-img"
                            />
                          </Index.Box>
                          <Index.Box className="rewards-text-box">
                            <Index.Box className="rewards-mini-box">
                              <Index.Typography
                                component="p"
                                className="api-rate-text"
                              >
                                Rewards Type : <span>{row?.rewardtype}</span>{" "}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Typography
                              component="p"
                              className="api-rate-text"
                            >
                              Start Token : <span>{row?.starttokenid}</span>{" "}
                            </Index.Typography>

                            <Index.Typography
                              component="p"
                              className="api-rate-text"
                            >
                              End Token : <span>{row?.endtokenid}</span>{" "}
                            </Index.Typography>
                          </Index.Box>

                          <Index.Typography
                            className="sportz-card-subtitle"
                            component="p"
                          >
                            {row?.description &&
                              new DOMParser().parseFromString(
                                row?.description.replace(/&nbsp;/g, ""),
                                "text/html"
                              ).body.textContent}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    ))}
                    
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sportzchains;
