import React from 'react'
import Index from "../../Index";
import '../loader/loader.css'

const loader = () => {
    return (
        <>
            <Index.Box class="loader-wrapper">
                <Index.Box class="cssload-loader">
                    <Index.Box class="cssload-inner cssload-one"></Index.Box>
                    <Index.Box class="cssload-inner cssload-two"></Index.Box>
                    <Index.Box class="cssload-inner cssload-three"></Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default loader


export const SkeletonLoader =()=>{
    const skeletonStyle = {
      backgroundColor: "gray", 
      };
    return(
    
     <>
      <Index.Box className="spn-token-wrapper">
        <Index.Box className="spn-btn-main">
          <Index.Box className="spn-days">
            <Index.Skeleton
              variant="circular"
              width={60}
              height={60}
              style={skeletonStyle}
            />
            
            <Index.Skeleton width={100} height={50} style={skeletonStyle} />
          </Index.Box>
        
          <Index.Box className="staking-btn-sec">
            <Index.Skeleton height={70} width={180} style={skeletonStyle} />
          </Index.Box>
        </Index.Box>
        <Index.Box className="api-rate-main">
          <Index.Box className="api-rate-amount">
            <Index.Skeleton width={250}  height={40} style={skeletonStyle} />
            <Index.Skeleton width={250} height={40}  style={skeletonStyle} />
            <Index.Skeleton width={250} height={40}  style={skeletonStyle} />
            <Index.Skeleton width={250} height={40}  style={skeletonStyle} />
            <Index.Skeleton width={250} height={40}  style={skeletonStyle} />
            <Index.Skeleton width={250} height={40}  style={skeletonStyle} />
          </Index.Box>
          <Index.Box className="api-rate-value"></Index.Box>
        </Index.Box>
      </Index.Box>
    </>

     
    )
} 


export const SkeletonLoaderSmartStaking = () => {
  const skeletonStyle = {
    backgroundColor: "gray", 
  };
  return (
    <Index.Box className="spn-token-wrapper">
      {[1, 2].map((index) => (
        <Index.Box className="spn-token-wrapper" key={index}>
          <Index.Box className="spn-btn-main">
            <Index.Box className="spn-days">
              <Index.Skeleton variant="circular"  style={skeletonStyle} height={50} width={50}  />
              <Index.Skeleton  style={skeletonStyle} height={50} width={100} />
            </Index.Box>
            <Index.Box className="staking-btn-sec">
              <Index.Skeleton height={60}  style={skeletonStyle}  width={100} />
            </Index.Box>
          </Index.Box>
          <Index.Box className="api-rate-main">
            <Index.Box className="api-rate-amount">
              <Index.Skeleton  style={skeletonStyle} height={40} width={200} />
              <Index.Skeleton  style={skeletonStyle} height={40} width={200} />
              <Index.Skeleton  style={skeletonStyle} height={40} width={200} />
            </Index.Box>
            <Index.Box className="api-rate-value">
              <Index.Skeleton  style={skeletonStyle} height={50} width={100} />
              <Index.Skeleton  style={skeletonStyle} height={30} width={100} />
              <Index.Skeleton  style={skeletonStyle} height={30} width={100} />
              <Index.Skeleton  style={skeletonStyle} height={50} width={150} />
            </Index.Box>
          </Index.Box>
          <Index.Skeleton  style={skeletonStyle} height={40} width={400} />
          <Index.Skeleton  style={skeletonStyle} height={35} width={900} />
          <Index.Skeleton  style={skeletonStyle} height={35} width={900} />
          <Index.Skeleton  style={skeletonStyle} height={35} width={900} />
          <Index.Skeleton  style={skeletonStyle} height={35} width={800} />
        
        </Index.Box>
      ))}
    </Index.Box>
  );
};