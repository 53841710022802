import React from "react";
import "./smartstaking.css";
import "./smartstaking.responsive.css";
import Index from "../../../Index";
import PagesIndex from "../../../../container/PagesIndex";
import { useAccount } from "wagmi";
import { Signer, ethers, utils } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import {
  DugoutObj,
  SPNTokenObj,
} from "../../../../Connectivity/ContractObject";

function SmartStaking() {
  const [data, setData] = PagesIndex.useState([]);
  const [loading, setLoading] = PagesIndex.useState(false);
  const [totalUser, setTotalUser] = PagesIndex.useState(0);
  const [totalStakeToken, setTotalStakeToken] = PagesIndex.useState(0);
  const navigate = PagesIndex.useNavigate();
  const signer = useEthersSigner();
  const account = useAccount();
  const dugout = DugoutObj(signer)
    .then(async (res) => {
      let totaluser = await res.getTotalUser();
      let totalStakeToken = await res.getTotalStakeToken();
      setTotalUser(totaluser.toString());
      setTotalStakeToken(utils.formatEther(totalStakeToken));
    })
    .catch((err) => {
      return err;
    });

  const getData = async () => {
    try {
      setLoading(true);
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.GET_LOCK_PERIOD
      );
      setData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  PagesIndex.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Index.Box className="home-container">
        <Index.Box className="smart-staking-wrapper">
          <Index.Box className="home-title-sec">
            <Index.Typography className="home-title" component="p">
              Secure Your <span> Future </span> Growth : Stake{" "}
              <span> Smarter </span> with Sportzchain
            </Index.Typography>
            <Index.Typography className="sub-title" component="p">
              Stake securely on Sportzchain's platform and watch your rewards
              grow based on your staked SPN tokens. Your assets are safe while
              earning rewards. Stay ahead by staking now and unlock the
              potential for future rewards with upcoming Fan tokens staking.
            </Index.Typography>
          </Index.Box>

          <Index.Box className="spn-wrapper">
            <Index.Box className="home-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  rowGap={6}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="staking-card">
                      <Index.Typography
                        className="staking-card-title"
                        component="p"
                      >
                        {totalStakeToken} <span>SPN</span>
                      </Index.Typography>
                      <Index.Typography
                        className="staking-card-subtitle"
                        component="p"
                      >
                        Total Staked Tokens
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="staking-card">
                      <Index.Typography
                        className="staking-card-title"
                        component="p"
                      >
                        {totalUser}
                      </Index.Typography>
                      <Index.Typography
                        className="staking-card-subtitle"
                        component="p"
                      >
                        Number of Stakers
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="home-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
              >
                {data &&
                  data.map((item, ind) => {
                    return (
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box
                          className="staking-card-days-navigate"
                          onClick={() => {
                            navigate("/stack-detail", { state: item });
                          }}
                        >
                          <Index.Box className="staking-card stacking-card-days">
                            <Index.Typography
                              className="staking-card-title staking-card-days-title"
                              component="p"
                            >
                              <span>SPN</span>
                            </Index.Typography>
                            <Index.Typography
                              className="staking-card-days-subtitle"
                              component="p"
                            >
                              {`${item?.day} Days`}
                            </Index.Typography>
                            <Index.Typography
                              className="staking-card-subtitle"
                              component="p"
                            >
                              {`APY: ${item?.apyrate}%`}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
              </Index.Box>
            </Index.Box>
          </Index.Box>

        </Index.Box>

        <Index.Box className="home-title-sec coming-soon-main-box">
          <Index.Typography className="home-title" component="p">
            FAN <span>Token</span> Staking
          </Index.Typography>
          
          <Index.Typography className="sub-title" component="p">
                    First-Ever: Stake Your Fan Token, Earn SPN Tokens! Be the
                    Pioneer in Exclusive Fan Token Staking – Coming Soon!
                  </Index.Typography>

          <Index.Box sx={{ width: 1 }} className="grid-main coming-soon-wrapper">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="hero-img-sec">
                  <img
                    src={PagesIndex.Png.heroImg}
                    alt="heroImg"
                    className="home-img"
                  />
                </Index.Box>

              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="staking-card cart-staking">
                  <Index.Typography
                    className="coming-soon-title "
                    component="p"
                  >
                    Coming Soon
                  </Index.Typography>

                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.GoToTop />
    </>
  );
}

export default SmartStaking;
