import React from "react";
import {SPNToken,NFT,Dugout} from "../Connectivity/AddressHelper";
import NFTAbi from "../Connectivity/abis/NFT.json";
import SPNAbi from "../Connectivity/abis/SPNToken.json";
import DugOutAbi from "../Connectivity/abis/Dugout.json";
import { ethers } from "ethers";

export const SPNTokenObj = async (Signer) => {
    return new ethers.Contract(SPNToken, SPNAbi.abi, Signer);
}

export const DugoutObj = async (Signer) => {
    return new ethers.Contract(Dugout, DugOutAbi.abi, Signer);
}

export const NFTObj = async (Signer) => {
    return new ethers.Contract(NFT, NFTAbi.abi, Signer);
}