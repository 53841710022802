import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../../components/PagesIndex'

function Layout() {
    return (
        <>

            <PagesIndex.Header/>
            <Index.Box className="page-content-main">
                <PagesIndex.Outlet/>
            </Index.Box>
            <PagesIndex.Footer/>
        </>
    )
}

export default Layout
