import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Header from "./user/defaultlayout/Header";
import Footer from "./user/defaultlayout/Footer";
import { Outlet } from "react-router-dom";
import HeroSection from "./user/pages/Home/HeroSection";
import Sportzchains from "./user/pages/Home/Sportzchains";
import SmartStaking from "./user/pages/Home/SmartStaking";
import GoToTop from "../components/GoToTop";
import { Api } from '../config/Api'
import DataService from '../config/DataService';
import { SkeletonLoader } from "./common/loader/loader";
import { useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
const PagesIndex = {
  GoToTop,
  useState,
  useEffect,
  useLocation,
  useNavigate,
  Api,
  SkeletonLoader,
  DataService,
  Svg,
  Png,
  Header,
  Footer,
  Outlet,
  HeroSection,
  Sportzchains,
  SmartStaking,
  dayjs,
};

export default PagesIndex;
