import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../container/user/pages/home/Home";
import Layout from "../container/user/pages/layout/Layout";
import StackDetail from "../components/user/pages/Home/StackDetail";
import TermsCondition from "../container/user/pages/termsCondition/TermsCondition";
import PrivacyPolicy from "../container/user/pages/privacyPolicy/PrivacyPolicy";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
    
        <Route path="" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="stack-detail" element={<StackDetail />} />
          <Route path="terms&conditon" element={<TermsCondition />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Route>

          
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
