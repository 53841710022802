import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./routes/Routers";
import "../src/assets/css/global.css";
import "../src/assets/css/global.responsive.css";
import '../src/container/user/pages/home/home.css'
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  arbitrum,
  mainnet,
  polygonMumbai,
  bscTestnet,
  sepolia
} from "wagmi/chains";

const chains = [arbitrum, mainnet, polygonMumbai, bscTestnet, sepolia];
const projectId = "2fa6c12dde1b5cd1a88e4fd4cb690bca";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
function App() {
  // {
  //   console.log = () => {};
  //   console.error = () => {};
  //   console.debug = () => {};
  // }
  return (
    <div className="App">
      <ToastContainer />
      <WagmiConfig config={wagmiConfig}>
        <Routers />
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </div>
  );
}

export default App;
