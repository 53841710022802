import React, { useEffect } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [navigate]);
  return (
    <>
      <Index.Box className="footer-wrapper">
        <Index.Box className="home-container">
          <Index.Box className="footer-main">
            <Index.Box className="footer-privacy">
              <Index.Link>
                <img src={PagesIndex.Png.logo} className="footer-logo" />
              </Index.Link>
              <Index.Link  to="/privacy-policy" target="_blank"  >
                <Index.Typography component="p" className="footer-link" >
                  Privacy Policy
                </Index.Typography>
              </Index.Link>
              <Index.Link to="/terms&conditon" target="_blank"  >
                <Index.Typography component="p" className="footer-link">
                Terms & Conditions
                </Index.Typography>
              </Index.Link>
              {/* <Index.Link>
                <Index.Typography component="p" className="footer-link">
                  Contact us
                </Index.Typography>
              </Index.Link> */}
            </Index.Box>
            <Index.Box className="footer-social-link-sec">
              <Index.Box className="footer-social-link">
                <Index.Link to="https://www.facebook.com/people/SPORTZCHAIN/100068654960827/" target="_blank" className='social-link'>
                  <img src={PagesIndex.Png.fbIc} className="social-icon" />
                </Index.Link>
                <Index.Link to="https://www.instagram.com/accounts/login/?next=%2Fsportzchain%2F" target="_blank" className="social-link">
                  <img src={PagesIndex.Png.instaIc} className="social-icon" />
                </Index.Link>
                <Index.Link to="https://twitter.com/sportzchain" target="_blank" className="social-link">
                  <img src={PagesIndex.Png.twitter} className="social-icon" />
                </Index.Link>
                <Index.Link to="https://t.me/SportzchainCommunity" target="_blank" className="social-link">
                  <img src={PagesIndex.Png.telegram} className="social-icon" />
                </Index.Link>
                <Index.Link to="https://www.linkedin.com/company/sportzchain/" target="_blank" className="social-link">
                  <img src={PagesIndex.Png.linkedin} className="social-icon" />
                </Index.Link>
                <Index.Link to="https://medium.com/@SportZchain" target="_blank" className="social-link">
                  <img src={PagesIndex.Png.seeklogo} className="social-icon" />
                </Index.Link>
                <Index.Link to="https://discord.com/invite/E4X3Gg9nbK" target="_blank" className="social-link">
                  <img src={PagesIndex.Png.discord} className="social-icon" />
                </Index.Link>
                <Index.Link to="https://www.reddit.com/r/sportzchain/" target="_blank" className="social-link">
                  <img src={PagesIndex.Png.reddit} className="social-icon" />
                </Index.Link>
              </Index.Box>
              <Index.Typography component="p" className="social-link-text">
                Sportzchain, the leading Engage-To-Earn (E2E) platform fueled by
                $SPN Token. Discover unique features – Emoji Battlegrounds,
                Predict and Win, Fan Commerce, Gamefi, shaping a new era of fan
                engagement in sports. Join us in redefining the future of fan
                experiences!
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="copyright-sec">
          <Index.Box className="home-container">
            <Index.Box className="copyright-main">
              <Index.Typography component="p" className="copyright-text">
                Copyright 2022, Sportzchain.com
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Footer;
