import React, { useEffect, useState } from 'react'
import PagesIndex from '../../../PagesIndex';
import Index from '../../../Index';

const TermsCondition = () => {
    const [data, setData] = useState("");
    const getData = async () => {
      try {
        const res = await PagesIndex.DataService.get(
          PagesIndex.Api.GET_TERMS_AND_CONDITION
        );
        const cleanedData = res?.data?.data?.termsAndCondition?.description.replace(/<[^>]*>/g, ''); // Remove HTML tags
        setData(cleanedData);
      } catch (error) {
        console.log(error, "error");
      }
    };
  
    useEffect(() => {
        getData();
      }, []);
  return (
    <div className="policy-main-box">
    <Index.Box className="policy-section">
      <Index.Box className="policy-heading">
        <Index.Typography variant="h4"> Terms & Conditions</Index.Typography>
      </Index.Box>

      <Index.Box className="policy-content">
        <Index.Typography>{data} </Index.Typography>
      </Index.Box>
    </Index.Box>
  </div>
  )
}

export default TermsCondition