import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";

const PrivacyPolicy = () => {
  const [data, setData] = useState("");
  const getData = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.GET_PRIVACY_POLICY
      );
      const cleanedData = res?.data?.data?.privacyPolicy?.description.replace(
        /<[^>]*>/g,
        ""
      ); 
      setData(cleanedData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="policy-main-box">
      <Index.Box className="policy-section">
        <Index.Box className="policy-heading">
          <Index.Typography variant="h4"> Privacy Policy</Index.Typography>
        </Index.Box>

        <Index.Box className="policy-content">
          <Index.Typography>{data} </Index.Typography>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default PrivacyPolicy;
