import React from 'react'
import '../home/home.css'
import Index from '../../../Index'
import PagesIndex from '../../../../components/PagesIndex'

function Home() {
;
  return (
    <>
      <Index.Box className='home-wrapper'>
        <Index.Box className='hero-main-sec'>
          <PagesIndex.HeroSection />
        </Index.Box>
        <Index.Box className='sportzchain-main-sec'>
          <PagesIndex.Sportzchains />
        </Index.Box>
        <Index.Box className='staking-main-sec'>
          <PagesIndex.SmartStaking />
        </Index.Box>
      </Index.Box>

    </>
  )
}

export default Home
