import React, { useEffect } from "react";
import Index from "../../../Index";
import "../Home/herosection.css";
import "../Home/herosection.responsive.css";
import PagesIndex from "../../../PagesIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function HeroSection() {
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  useEffect(() => {
    const lastModalShownTimestamp = localStorage.getItem(
      "lastModalShownTimestamp"
    );
    const now = new Date().getTime();
    if (
      !lastModalShownTimestamp ||
      now - lastModalShownTimestamp > 24 * 60 * 60 * 1000
    ) {
      handleOpenDelete();
      localStorage.setItem("lastModalShownTimestamp", now);
    }
  }, []);

  return (
    <>
      <Index.Box className="home-container">
        <Index.Box className="hero-wrapper">
          <Index.Box className="home-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                alignItems="center"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 3, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="hero-content">
                    <Index.Typography className="hero-title" component="h1">
                      Unlock Passive Income : Stake with Security
                    </Index.Typography>
                    <Index.Typography className="hero-sub-title" component="h1">
                      Unlock the security and reliability you deserve with
                      Sportzchain's non-custodial staking platform. Safeguard
                      your assets while maximizing rewards, ensuring peace of
                      mind every step of the way.
                    </Index.Typography>
                
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 6",
                  }}
                  justifySelf="center"
                  className="grid-column"
                >
                  <Index.Box className="hero-img-sec">
                    <img
                      src={PagesIndex.Png.heroImg}
                      alt="heroImg"
                      className="home-img"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="delete-modal-inner-main modal-inner nfc-inner-modal"
        >
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Get ready to earn $SPN while you HODL
          </Index.Typography>
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.moneyGrowth} className="user-growth-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Explore the world of <span> Sportzchain </span> staking{" "}
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex nfc-btn-sec">
            <Index.Button className="modal-delete-btn modal-btn">
              {" "}
              Explore Sportzchain Now{" "}
            </Index.Button>
          </Index.Box>
          <img
            src={PagesIndex.Svg.cross}
            className="cancel-btn"
            onClick={handleCloseDelete}
          />
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default HeroSection;
