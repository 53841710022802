import React from "react";
import Index from "../../../Index";

export default function PrimaryButton(props) {
  return (
    <>
      <Index.Box className="primary-btn-main">
        <Index.Button
          className={props.className}
          src={props.src}
          onClick={props.onClick}
          type={props.type}
        >
          <img src={props.imgSrc} alt="" className={props.imgClassName} />
          <span>{props.btnLabel}</span>
        </Index.Button>
      </Index.Box>
    </>
  );
}

//  PrimaryButton;
