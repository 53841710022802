import Svg from "../assets/Svg";
import Png from "../assets/Png";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import DataService, { IMAGE_URL_ENDPOINT } from "../config/DataService";
import { Api } from "../config/Api";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import { useEffect,useState } from "react";
import GoToTop from "../components/GoToTop";
import { SkeletonLoaderSmartStaking } from "../components/common/loader/loader";
const PagesIndex = {
  GoToTop,
  Formik,
  SkeletonLoaderSmartStaking,
  Svg,
  Png,
  CKEditor,
  ClassicEditor,
  toast,
  ToastContainer,
  Outlet,
  Link,
  useEffect,
  useState,
  useDispatch,
  useNavigate,
  Navigate,
  useLocation,
  useSelector,
  axios,
  DataService,
  IMAGE_URL_ENDPOINT,
  Api,
  dayjs,
};

export default PagesIndex;
