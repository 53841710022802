import React, { useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "../defaultlayout/defaultlayout.css";
import "../defaultlayout/defaultlayout.responsive.css";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { Signer, ethers } from "ethers";
import { useEthersSigner } from "../../../Connectivity/WalletSignerprovider";
import { useDisconnect } from 'wagmi'

function Header() {
  const [anchorEl, setAnchorEl] = React.useState();
  const opendrop = Boolean(anchorEl);
  const navigate = PagesIndex.useNavigate()
  const { disconnect } = useDisconnect()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { open, close } = useWeb3Modal();
  const signer = useEthersSigner();
  const { account, address, isConnected } = useAccount();

  const handlesubmitforConnect = async () => {
    await open();
  };

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileNav = () => {
    if (!mobileNavOpen) {
      setMobileNavOpen(true);
      document.body.classList.add("body-overflow");
    } else {
      setMobileNavOpen(false);
      document.body.classList.remove("body-overflow");
    }
  };

  return (
    <>
      <Index.Box className="header-wrapper">
        <Index.Box className="home-container">
          <Index.Box className="home-main">
            <Index.Box className="header-logo">
              <img src={PagesIndex.Png.logo} alt="logo" className="home-logo" onClick={() => { navigate("/") }} />
            </Index.Box>
            <Index.Box className={`header-list ${mobileNavOpen ? 'mobile-nav-open' : ''}`}>
              <Index.List className="header-ul">
                <Index.ListItem className="header-li active">
                  <Index.Link to="/">Home</Index.Link>
                </Index.ListItem>
                <Index.ListItem className="header-li">
                  <Index.Link to="https://sportzchain.com/spn-token-page/" target="_blank">$SPN Token</Index.Link>
                </Index.ListItem>
                <Index.ListItem className="header-li">
                  <Index.Link to="https://fans.sportzchain.com/" target="_blank">dApp</Index.Link>
                </Index.ListItem>
                <Index.ListItem className="header-li">
                  <Index.Link to="https://www.dextools.io/app/en/polygon/pair-explorer/0x1f3627fe74c08da410bc4cf0456dda647972cc19" target="_blank">Buy $SPN</Index.Link>
                </Index.ListItem>
                <Index.ListItem className="mobile-nav-close" onClick={mobileNav}>
                  X
                </Index.ListItem>
              </Index.List>
              <Index.Box className="meta-wallet meta-box">
                {!isConnected ? (
                  <Index.Button
                    className="metamask-btn"
                    onClick={handlesubmitforConnect}
                  >
                    <img
                      src={PagesIndex.Svg.wallet}
                      alt="MetaMask"
                      className="metamask-img"
                    />
                    Connect <br /> Wallet
                    <Index.Typography
                      component="p"
                      className="metamsk-text"
                    ></Index.Typography>
                  </Index.Button>
                ) : (
                  <Index.Box className="metamask-btn meta-address">
                    <Index.Button
                      id="fade-button"
                      sx={{
                        color: 'white', fontSize: '14px',
                        fontFamily: 'poppins-semibold'
                      }}
                      aria-controls={opendrop ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={opendrop ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {`${address.slice(0, 6)}...${address.slice(-4)}`}
                    </Index.Button>

                    <Index.Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      anchorEl={anchorEl}
                      open={opendrop}
                      onClose={handleClose}
                      TransitionComponent={Index.Fade}
                      className="metamask-disconnect"
                    >
                      <Index.MenuItem onClick={() => {
                        disconnect();
                        handleClose();
                      }}>
                        Wallet Disconnect
                      </Index.MenuItem>
                    </Index.Menu>
                  </Index.Box>
                )}
              </Index.Box>
              <Index.Button className="mobile-menu-btn" onClick={mobileNav}>
                <Index.MenuIcon />
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Header;
